<template>
     <v-card
        elevation="10"
        class="mx-auto"
    >
        <v-toolbar
            dark
            cards                
            flat
            outlined
            color="primary"
        >
            <v-card-title class="text-h6 font-weight-regular">
            {{cmpOptions.title}}
            </v-card-title>


            <v-spacer> </v-spacer>
  
            <v-btn 
                @click= "close($event)"
                icon>
                <v-icon>mdi-close-thick</v-icon>
            </v-btn>
 
        </v-toolbar>
        <v-divider></v-divider>
        <v-container>
                <v-form
                    ref="form"
                    v-model="form"
                    @submit.prevent="submit"
                    class="pa-4 pt-6"
                >
                    <v-alert type="error" v-if="errMessage != ''">
                    {{errMessage}}
                    </v-alert>
                    <v-text-field
                        v-model="editUser.username"
                        :rules="[rules.required]"
                        label="Username"
                        style="min-height: 96px">
                    </v-text-field>
<!--
                    <v-text-field
                        v-model="user.name"
                        :rules="[rules.required]"
                        label="Name"
                        style="min-height: 96px">
                    </v-text-field>
-->
                    <v-text-field
                        v-model="editUser.email"
                        :rules="[rules.required]"
                        label="E-Mail"
                        style="min-height: 96px">
                    </v-text-field>
                    <v-autocomplete
                        v-if="!cmpOptions.isEdit"
                        v-model="selectedItems"
                        :items="availableCompanies"
                        item-text="name"
                        item-value="id"
                        :menu-props="{closeOnContentClick:true}"
                        chips
                        filled
                        multiple
                        label="Betriebe"
                        :rules="[rules.required]"
                    >
                        <template v-slot:selection="data">
                            <v-chip
                            v-bind="data.attrs"
                            :input-value="data.selected"
                            close
                            @click="data.select"
                            @click:close="removeItem(data.item)"
                            >
                            <v-icon class="mr-1">
                            mdi-greenhouse
                            </v-icon>
                            {{ data.item.name }}
                            </v-chip>
                        </template>
                        <template v-slot:item="data">
                            <template v-if="typeof data.item !== 'object'">
                                <v-list-item-content v-text="data.item"></v-list-item-content>
                            </template>
                            <template v-else>
                                <v-icon class="mr-4">
                                    mdi-greenhouse
                                </v-icon>
                                <v-list-item-content>
                                    <v-list-item-title v-html="data.item.name"></v-list-item-title>
                                    <v-list-item-subtitle v-html="data.item.town"></v-list-item-subtitle>
                                </v-list-item-content>
                            </template>
                        </template>
                    </v-autocomplete>
                    <v-select
                        v-model="selectedRoleId"
                        :items="availableRoles"
                        item-text="caption"
                        item-value="id"
                        label="Role"
                        filled
                        @change="changeRole"
                    >
                    </v-select>
                    <v-text-field
                        v-if="!cmpOptions.isEdit"
                        v-model="editUser.password"
                        :rules="[rules.required]"
                        label="Passwort"
                        name="password" 
                        autocomplete="off"
                        style="min-height: 96px"
                        type="password">
                    </v-text-field>
                    <v-text-field
                        v-if="!cmpOptions.isEdit"
                        v-model="editUser.password_repeat"
                        :rules="[rules.required]"
                        label="Password Wiederholung"
                        name="password" 
                        autocomplete="off"
                        style="min-height: 96px"
                        type="password">
                    </v-text-field>

                    <v-divider></v-divider>
                    <v-card-actions>
                    <v-btn
                        
                        outlined
                        @click="$refs.form.reset()"
                    >
                        Reset
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        :disabled="!form"
                        :loading="isLoading"
                        dark
                        color="primary"
                        depressed
                        type="submit"              
                    >
                        Speichern
                    </v-btn>
                </v-card-actions>
            </v-form>
        </v-container>
    </v-card>
</template>

<script>
    // import ManagementService from "../../services/management.service";
    export default {
  
 
        props:{
            user: {
                required: false,
                type: Object,
                default: () => undefined
            },
            availableRoles:{
                required: false,
                type: Array,
                default: () => undefined
            },
            options:{
                required: false,
                type: Object
            },
            isVisible:{                
                required: true,
                default: false,
                type: Boolean
            }
        },

        data() {
            return {
                defaultOptions:{
                    title: 'Title',
                    isEdit: true
                },
                defaultUser: {
                    username: null,
                    name:'',
                    givenname:'',
                    email: null,
                    password: null,
                    password_repeat: null,
                    role: null,
                    companies: null
                },
                editUser: {
                    username: null,
                    name:'',
                    givenname:'',
                    email: null,
                    password: null,
                    password_repeat: null,
                    role: null,
                    companies: null
                },

                rules: {
                    required: v => !! v || 'This field is required'
                }, 
                isLoading: false,
                message: '',
                errMessage: '',
                form: false,
                selectedItems: [],    
                selectedRoleId: undefined,
                selectedRole:{},
            }
        },

        computed:{            
 
            currentUser() {
                return this.$store.state.auth.user;
            },

            availableCompanies: function() {
                    return this.currentUser.linkedCompanies;
            },

            cmpOptions(){
                //console.debug('cmpOptions');
                // console.debug('cmpOptions', {...this.defaultOptions,...this.options}, 'sds', this.currentUser);
                return {...this.defaultOptions,...this.options};
            },

            captionBtnSubmit(){
                return this.cmpOptions.isEdit ? 'Ändern' : 'Speichern';
            },


        },

        methods:{ 


            close(event){
                console.debug('close', event)
                this.$emit('close', event)  
                this.$emit('update:isVisible', false)        
            },

            submit(event){
                console.debug('submit', event)
                const user = this.editUser;
                if(user.roleLevel != this.selectedRoleId){
                    user.securitymask = this.selectedRole.securitymask;
                    user.roleLevel = this.selectedRoleId;
                }

                if(!this.cmpOptions.isEdit && this.selectedItems.length == 0 && user.roleLevel < 750){
                            this.errMessage = 'Bitte einen Betrieb hinzufügen'
                            return;
                }

                if(!this.cmpOptions.isEdit)
                    this.editUser.companies = this.selectedItems
 
                console.debug('submit', this.editUser)
                const _this = this
                const apicmd = this.cmpOptions.isEdit ? "putApiSetStore" : "postApiSetStore"
                this.$store.dispatch('management/' + apicmd, {path:"api/management/user", param:{ store:"accounts", data:this.editUser}})
                .then((response)=>{ 
                    console.log("submit:", response); 
                    this.$emit('close')  

                })
                .catch((error)=>{
                    _this.errMessage = error
                    console.error(error);
                })
 

       
            },
            changeRole(item){
                console.debug('changeRole')
                let obj = this.availableRoles.find(o => o.id === item);
                this.selectedRole = obj;
            },

            removeItem(item) {
                console.debug('removeItem', item)
                const index = this.selectedItems.indexOf(item.id)
                if (index >= 0) this.selectedItems.splice(index, 1)
            },


 
        },
        watch: { 
            isVisible: function(newVal) { // watch it
                this.errMessage  = '';
                console.debug('isVisible');
                if(newVal){
                    this.$refs.form.reset();
                    if(this.cmpOptions.isEdit){
                        console.debug('isVisible', this.user);
                        this.editUser = JSON.parse(JSON.stringify(this.user));
                        this.selectedRoleId = this.editUser.roleLevel;
                    }
                    else{
                        
                        this.editUser = JSON.parse(JSON.stringify(this.defaultUser));
                    }
                }
                else{
                    this.editUser = JSON.parse(JSON.stringify(this.defaultUser));
                    this.selectedRoleId = undefined;
                }

                console.log('Prop changed: isVisible', newVal, ' | user: ', this.editUser)
            }
        },
        mounted() {
            this.errMessage  = '';
            console.debug('mounted');
            if(this.cmpOptions.isEdit){
                console.debug('isVisible', this.user);
                this.editUser = JSON.parse(JSON.stringify(this.user));
                this.selectedRoleId = this.editUser.roleLevel;
            }
            else{
                
                this.editUser = JSON.parse(JSON.stringify(this.defaultUser));
            }
        }

    }

</script>